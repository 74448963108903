<template lang="pug">
.om-wizard-pick-theme
  .text-center
    wizard-title.mb-3 {{ $t('onboarding.customTheme.pickTheme.title') }}
    om-body-text(bt400md) {{ $t('onboarding.customTheme.pickTheme.description') }}
    .row(:style="'margin-top: 3rem'")
      .col-6.wizard-theme(v-for="theme in showableThemes" :key="theme.name")
        .wizard-theme-item
          .wizard-theme-head
            .dot(v-for="dot in 3")
          .wizard-theme-content
            template-frame(
              @observable="addObservable($event.$el)"
              v-if="theme.mainTemplate"
              @inited="updateDimensions"
              :dimensions="boxDimensions"
              :color="color"
              allowSsr
              :template="theme.mainTemplate"
              @contentLoaded="onContentLoaded"
              :endpoint="'themekit-preview'"
              :payload="{ themeKit: { colors: { mainColor: color } }, baseTheme: true }"
            )
            .overlay-button
              om-button(
                @click="nextPage(theme)"
                :data-track="'wizard-pickTheme-' + theme.name"
                primary
                large
              ) {{ $t('choose') }}
</template>
<script>
  import GET_CUSTOM_THEMES from '@/graphql/GetCustomThemes.gql';
  import previewParentMixin from '@/mixins/previewParent';
  import ssrMixin from '@/mixins/ssr';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import sharedMixin from '../shared';
  import navigationMixin from '../navigation';

  export default {
    mixins: [sharedMixin, navigationMixin, previewParentMixin, ssrMixin, observableCollectionMixin],
    data: () => ({
      theme: null,
      customThemes: null,
    }),
    apollo: {
      customThemes: {
        query: GET_CUSTOM_THEMES,
        variables: {
          resolveMainTemplate: true,
        },
        update({ themes }) {
          return themes;
        },
      },
    },
    computed: {
      color() {
        return this.$route.query.color;
      },
      customThemesWithMainTemplate() {
        return this.customThemes.base.filter(({ mainTemplate, themeKit, templates }) => {
          return !!mainTemplate && themeKit?.colors && !!templates?.length;
        });
      },
      showableThemes() {
        if (this.customThemes) {
          return this.customThemesWithMainTemplate;
        }
        return this.themes;
      },
    },
    methods: {
      async nextPage(theme) {
        this.theme = theme.name;

        await this.updateWizardPreferences({ customThemeId: theme._id, style: this.theme });
        await this.next(null, { baseThemeId: theme._id, theme: this.theme });
      },
    },
  };
</script>
<style scoped lang="sass">
  @import '@/sass/pages/_wizard.sass'
</style>
