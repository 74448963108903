<template lang="pug">
div
  smart-wizard-page(
    :monk="'monk-seasonal'"
    :heading="$t('onboarding.wizard.pages.seasonalSales.title')"
  )
    template(#lead)
      p {{ $t('onboarding.wizard.pages.seasonalSales.description') }}
    template(#actions)
      om-select-cards(v-model="selected" :options="options" vertical)
</template>

<script>
  import { mapGetters } from 'vuex';
  import { lsStore } from '@/helpers/localStorage';
  import { WIZARD_SETUP_KEY } from '@/config/constants';
  import navigationMixin from '../navigation';
  import smartWizardMixin from '../smartWizard';
  import sharedMixin from '../shared';

  export default {
    mixins: [smartWizardMixin, navigationMixin, sharedMixin],
    data: () => ({ selected: null }),
    computed: {
      ...mapGetters(['wizardPreferences']),
      options() {
        const opts = ['yes', 'fewTimes', 'no'];
        return opts.map((value) => ({ value, key: this.getTranslation(value) }));
      },
      style() {
        return this.wizardPreferences ? this.wizardPreferences.style || null : null;
      },
      selectedTheme() {
        return this.$route.query.theme || this.style;
      },
    },
    watch: {
      async selected(v) {
        if (!v) return;
        lsStore(WIZARD_SETUP_KEY, '1', false);
        const query = { ...this.$route.query, [this.$route.params.step]: v };
        const inputs = this.getWizardPreferences(query);
        await this.updateWizardPreferences({
          ...inputs,
          style: this.selectedTheme,
        });
        this.option = v;
      },
    },
    methods: {
      getTranslation(value) {
        return this.$t(`onboarding.wizard.pages.seasonalSales.options.${value}`);
      },
    },
  };
</script>
