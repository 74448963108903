<template lang="pug">
.wizard-color-palette(:class="{ 'picker-open': pickerOpen }")
  .text-left
    .wizard-color-palette-subtitle {{ $t('mainColor') }}
  .d-flex.color-box.main-color-new.pointer
    .base-color(
      :style="{ 'background-color': color }"
      @click.stop="showPicker($event, 0, '.main-color-new')"
    )
  template(v-if="secondaryColors && secondaryColors.length")
    .text-left.mt-4
      .wizard-color-palette-subtitle {{ $t('secondaryColors') }}
    .d-flex.color-box.main-color-derivate
      .secondary-color.pointer(
        v-for="(secondaryColor, index) in secondaryColors"
        :style="{ 'background-color': secondaryColor.themeColor }"
        :key="`secondary-color-${index}-${secondaryColor}`"
        @click.stop="showPicker($event, index + 1, '.main-color-derivate')"
      )
  SimpleColorPicker(
    v-if="pickerOpen"
    @click.stop
    :position="pickerPosition"
    :value="{ hex }"
    @change="colorChanged"
    zIndex="1200"
  )
</template>
<script>
  import SimpleColorPicker from '@/components/Wizard/SimpleColorPicker.vue';
  import { brandkitTracker } from '@/services/userInteractionTracker/tracker';
  import { debounce } from 'lodash-es';

  const _clone = (v) => JSON.parse(JSON.stringify(v));

  export default {
    components: { SimpleColorPicker },
    props: {
      themeColors: {
        type: Array,
        default: null,
      },
      color: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        pickerOpen: false,
        selectedColor: null,
        secondaryColors: [],
        pickerPosition: null,
      };
    },
    computed: {
      hex() {
        if (!this.selectedColor) return this.color;
        return this.secondaryColors[this.selectedColor - 1].themeColor;
      },
    },
    watch: {
      themeColors(n) {
        if (n) this.secondaryColors = [..._clone(this.themeColors)];
      },
    },
    mounted() {
      this.secondaryColors = this.themeColors ? [..._clone(this.themeColors)] : [];
    },
    methods: {
      showPicker(event, index, holderSelector) {
        this.selectedColor = index;
        const holder = event.target.closest(holderSelector);
        const { height, width } = holder.getClientRects()[0];
        this.pickerPosition = {
          top: holder.offsetTop + height + 10,
          width,
          left: holder.offsetLeft,
        };
        this.$emit('backdropVisbility', true);
        this.pickerOpen = true;
      },
      hideColorPicker() {
        this.pickerOpen = false;
        this.$emit('backdropVisbility', false);
      },

      colorChanged: debounce(function (color) {
        const current = this.getColor(this.selectedColor);
        if (current !== color) {
          this.setColor(this.selectedColor, color);
        }
      }, 300),
      setColor(index, color) {
        if (!index) {
          this.$emit('mainColor', color);
          brandkitTracker.trackChange('wizardMainColor', { value: color });
        } else {
          this.secondaryColors[index - 1].themeColor = color;
          this.$emit('secondaryColors', this.secondaryColors);
          brandkitTracker.trackChange('wizardThemeColors', { value: this.secondaryColors });
        }
      },
      getColor(index) {
        if (!index) {
          return this.color;
        }
        return this.secondaryColors[index - 1].themeColor;
      },
    },
  };
</script>
<style lang="sass">
  .color-box
    border-radius: 6px
    height: 2.3rem
    width: 100%
  .base-color,
  .secondary-color
    width: 100%
    height: 100%
    border-radius: 4px
    border: 1px solid #E3E5E8
  .main-color-derivate
    justify-content: space-between
  .secondary-color
    flex: 0 0 calc(25% - 8px)
  .wizard-color-palette-subtitle
    font-size: 0.75rem
  .wizard-color-picker-backdrop
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    .vc-sketch
      z-index: 2
</style>
