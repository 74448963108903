<template lang="pug">
.om-wizard-color
  .row.justify-content-center.pb-6
    .col-12.col-lg-5.pr-lg-3
      wizard-title.mb-3 {{ $t('onboarding.customTheme.start.title') }}
      om-body-text.pb-6(bt400md) {{ $t('onboarding.customTheme.start.description') }}
      om-color-palette(@selected="color = $event")
      .d-flex.align-items-center
        .om-wizard-color-picker.w-100
          .om-wizard-color-picker-label.mr-2
            om-body-text(bt400md) {{ $t('mainColor') }}:
          om-color-picker(fluid v-model="color")
    .col-12.col-lg-5.pl-lg-3
      .wizard-theme
        .wizard-theme-head
          .dot(v-for="dot in 3")
        .wizard-theme-content
          template-frame(
            v-if="dummyTemplate"
            @observable="addObservable($event.$el)"
            allowSsr
            :template="dummyTemplate"
            :dimensions="boxDimensions"
            :color="color"
            @inited="updateDimensions"
            @contentLoaded="onContentLoaded"
            removeHidden
          )
</template>

<script>
  import GET_DUMMY_TEMPLATE from '@/graphql/GetDummyTemplate.gql';
  import previewParentMixin from '@/mixins/previewParent';
  import { lsStore, lsRetrieve } from '@/helpers/localStorage';
  import { WIZARD_COLOR_STORE_KEY } from '@/config/constants';
  import ssrMixin from '@/mixins/ssr';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import navigationMixin from '../navigation';
  import sharedMixin from '../shared';

  export default {
    mixins: [navigationMixin, sharedMixin, previewParentMixin, ssrMixin, observableCollectionMixin],
    apollo: {
      dummy: {
        query: GET_DUMMY_TEMPLATE,
      },
    },
    data() {
      return {
        color: '',
      };
    },
    computed: {
      dummyTemplate() {
        return this.dummy !== undefined ? this.dummy[0] : null;
      },
    },
    watch: {
      color(v) {
        if (v && v.length) {
          lsStore(WIZARD_COLOR_STORE_KEY, v);
        }
        this.$emit('change', { color: this.color });
      },
      dummyTemplate() {
        this.$nextTick(() => {
          this.updateDimensions();
        });
      },
    },
    created() {
      this.color = lsRetrieve(WIZARD_COLOR_STORE_KEY) || '#F9BD42';
      this.$emit('change', { color: this.color });
    },
    methods: {
      nextPage() {
        this.next();
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/pages/_wizard.sass'

  @media (min-width: 1400px)
    .container
      max-width: 1200px
  .om-wizard-color
    .wizard-theme-head
      height: 20px
      .dot
        width: .70rem
        height: .70rem
    &-picker
      display: flex
      align-items: center
      border-top: 1px solid #D8DDE1
      padding-top: 20px
      margin-top: 20px

      .brand-color-picker
        flex: 1

        @media (max-height: 867px)
          .vc-sketch
            top: auto
            bottom: 4.25rem

      &-label
        color: #505763
        font-size: 0.75rem
        line-height: 1.5rem
    .brand-color-palette
      margin-top: 0
      margin-bottom: 0
      margin-left: -4px
      margin-right: -4px

      .brand-color-box
        width: 52px
        height: 30px

        margin-left: 4px
        margin-right: 4px
        margin-bottom: 8px
    .brand-color-picker-input-color
      height: 44px
      flex: 0 0 80px
    .brand-color-picker-input input
      height: 44px
      font-size: 16px
</style>
