<template lang="pug">
.om-wizard-color.pt-5.pb-6
  .row.justify-content-center
    .col-10
      wizard-title.mb-3 {{ $t('onboarding.customTheme.start.title') }}
      om-body-text(bt400md) {{ $t('onboarding.customTheme.start.description') }}
  .row.justify-content-center.pb-6
    .col-12.col-lg-5.pt-6
      om-color-palette(@selected="color = $event")
      .d-flex.align-items-center
        .om-wizard-color-picker.w-100
          .om-wizard-color-picker-label.mr-2
            om-body-text(bt400md) {{ $t('mainColor') }}:
          om-color-picker(fluid v-model="color")
    .col-12.col-lg-5.pt-6
      .row.justify-content-center
        om-color-preview-box(:color="color")
</template>

<script>
  import GET_DUMMY_TEMPLATE from '@/graphql/GetDummyTemplate.gql';
  import previewParentMixin from '@/mixins/previewParent';
  import { lsStore, lsRetrieve } from '@/helpers/localStorage';
  import { WIZARD_COLOR_STORE_KEY } from '@/config/constants';
  import { mapGetters } from 'vuex';
  import navigationMixin from '../navigation';
  import sharedMixin from '../shared';

  export default {
    mixins: [navigationMixin, sharedMixin, previewParentMixin],
    apollo: {
      dummy: {
        query: GET_DUMMY_TEMPLATE,
      },
    },
    data() {
      return {
        color: '',
      };
    },
    computed: {
      dummyTemplate() {
        return this.dummy !== undefined ? this.dummy[0] : null;
      },
      ...mapGetters(['getLocale']),
      lottieLoading() {
        return `wizard_templates_loading_${this.getLocale}`;
      },
    },
    watch: {
      color(v) {
        if (v && v.length) {
          lsStore(WIZARD_COLOR_STORE_KEY, v);
        }
      },
    },
    created() {
      this.color = lsRetrieve(WIZARD_COLOR_STORE_KEY) || '#F9BD42';
      // preload loading animation
      import(`@/lottie/${this.lottieLoading}.json`);
    },
    methods: {
      async nextPage() {
        await this.updateWizardPreferences({ color: this.color });
        await this.next();
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/pages/_wizard.sass'

  @media (min-width: 1400px)
    .container
      max-width: 1200px
  .om-wizard-color
    .wizard-theme-head
      height: 20px
      .dot
        width: .70rem
        height: .70rem
    &-picker
      display: flex
      align-items: center
      border-top: 1px solid #D8DDE1
      padding-top: 20px
      margin-top: 20px

      .brand-color-picker
        flex: 1

        @media (max-height: 867px)
          .vc-sketch
            top: auto
            bottom: 4.25rem

      &-label
        color: #505763
        font-size: 0.75rem
        line-height: 1.5rem
    .brand-color-palette
      margin-top: 0
      margin-bottom: 0
      margin-left: -4px
      margin-right: -4px

      .brand-color-box
        width: 52px
        height: 30px

        margin-left: 4px
        margin-right: 4px
        margin-bottom: 8px
    .brand-color-picker-input-color
      height: 44px
      flex: 0 0 80px
    .brand-color-picker-input input
      height: 44px
      font-size: 16px
</style>
