<template lang="pug">
om-modal(:name="name" adaptive :maxWidth="1440" width="90%" :modalClasses="name")
  template(slot="modal-header")
    .row
      .col
        .font-weight-bold.font-size-1--25 {{ $t('imageManager') }}
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide(name)")
        close-icon(:width="14" :height="14" :color="'#C2C2C2'")
  template(slot="modal-body")
    .upload-area.mb-5
      .upload-dnd
      .upload-container.d-flex.justify-content-center
        om-button(primary icon="cloud-upload" @click="openFileChooser") {{ $t('imageUploadNew') }}
        input#file-input.d-none(
          type="file"
          multiple="multiple"
          accept=".jpeg,.jpg,.jpeg-2000,.png,.svg,.gif,.tiff,.webp,.avif"
          ref="fileInput"
          @change="onFileChange"
        )
        .upload-size.upload-maxfile.p-2 {{ $t('imageMaxSize', { size: MAX_KBYTE, sizeMB: MAX_KBYTE / 1024 }) }}
    .user-images-body.d-flex.flex-wrap
      .user-images-item(v-for="(image, index) of images")
        inline-svg.user-images-image(v-if="image.url.includes('.svg')" :src="image.url")
        om-progressive-image.user-images-image(
          v-else
          :src="`${image.url}?ts=${timestamp(image.updatedAt)}`"
          placeholder="/om_picture.svg"
          backgroundColor="transparent"
        )
        .user-images-item-hover
          om-tag.user-images-name.mb-2.font-size-1--25.font-weight-bold(:title="image.name") {{ image.name }}
          om-button(primary @click="useImage(image)") {{ $t('imageUse') }}
</template>

<script>
  import { restClient as axios } from '@/editor/axios';
  import { UilCloudUpload } from '@iconscout/vue-unicons';
  import ALL_IMAGES from '@/graphql/AllImages.gql';

  const MAX_KBYTE = 4096;

  export const MODAL_NAME = 'user-images';

  export default {
    components: { UilCloudUpload },
    data: () => ({
      MAX_KBYTE,
      name: MODAL_NAME,
    }),
    apollo: {
      images: {
        query: ALL_IMAGES,
        result({ images }) {
          return images;
        },
      },
    },
    methods: {
      timestamp(str) {
        return new Date(str).getTime();
      },
      openFileChooser() {
        this.$refs.fileInput.click();
      },
      onFileChange(e) {
        this.createImages(e.target.files);
      },
      createImages(files) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file.type.match('^image/')) {
            this.createImage(file);
          } else {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.unsupportedFileFormat'),
            });
          }
        }
      },
      createImage(file) {
        const self = this;
        const reader = new FileReader();
        reader.fileName = file.name;
        this.$bus.$emit('showAdminLoader', true);
        self.newImageLoading = true;
        reader.onload = (e) => {
          const url = e.target.result;
          const htmlImage = new Image();
          htmlImage.src = url;
          htmlImage.onload = function () {
            const kbytes = file.size / 1024;
            if (kbytes > MAX_KBYTE) {
              const msg = self.$t('fileSizeToLargeMsg', { size: MAX_KBYTE });
              self.$bus.$emit('showAdminLoader', false);
              self.newImageLoading = false;
              // eslint-disable-next-line
              window.alert(msg);
              return;
            }
            const splitName = e.target.fileName.split('.');
            const name = `${splitName[0].substr(0, 17)}_${new Date().getTime()}.${splitName[1]}`;
            const data = new FormData();
            data.append('file', file, name);
            data.append('type', 'user');
            data.append('value', 'none');
            data.append('height', this.height);
            data.append('width', this.width);
            axios
              .post('upload/editor', data, { headers: { 'content-type': 'multipart/form-data' } })
              .then(() => {
                self.$apollo.queries.images.refetch();
                self.$emit('upload')
              })
              .catch((err) => {
                console.log('error', err);
                const text =
                  err.response && err.response.data && err.response.data.error
                    ? err.response.data.error
                    : self.$t('notifications.errorWhileUploadingImage');

                self.$notify({
                  type: 'error',
                  text,
                });

                self.$emit('upload')
              });
          };
        };
        reader.readAsDataURL(file);
      },
      useImage(image) {
        this.$emit('use', image);
        this.$modal.hide(this.name);
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .user-images
    &-item
      border: 1px solid $om-gray-300
      background: $om-gray-100
      border-radius: 7px
      display: flex
      align-items: center
      justify-content: center
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1)
      position: relative
      height: 13rem
      width: 100%
      max-width: 20rem
      padding: .5rem
      overflow: hidden
      &-hover
        position: absolute
        background: rgba(0,0,0,.5)
        display: none
        align-items: center
        justify-content: center
        flex-direction: column
        width: 100%
        height: 100%
        transition: .3s ease-out
        padding: .5rem
        &:hover
          display: flex
      &:hover
        .user-images-item-hover
          display: flex
    &-image
      width: 100%
      height: auto
      max-height: 100%
      object-fit: contain
    &-name
      display: block
      white-space: nowrap
      text-align: center
      width: 100%
      overflow: hidden
      text-overflow: ellipsis
    &-body
      max-height: 50vh
      overflow-y: scroll
      gap: 1rem
</style>
