<template lang="pug">
.simple-color-picker(@click.stop="" :style="styles")
  .vc-sketch
    .vc-sketch-saturation-wrap
      saturation(v-model="colors" @change="childChange")
    .vc-sketch-bottom
      .vc-sketch-field
        .vc-sketch-field--double.mb-2
          editable-input(label="hex" :value="hex" @change="inputChange")
      .vc-sketch-sliders
        .vc-sketch-hue-wrap
          hue(v-model="colors" @change="childChange")
</template>

<script>
  import tinycolor from 'tinycolor2';
  import editableInput from '@/editor/components/color/components/common/EditableInputOm.vue';
  import saturation from '@/editor/components/color/components/common/Saturation.vue';
  import hue from '@/editor/components/color/components/common/Hue.vue';
  import alpha from '@/editor/components/color/components/common/Alpha.vue';
  import colorMixin from '@/editor/components/color/mixin/color';

  export default {
    components: { saturation, hue, alpha, editableInput },
    mixins: [colorMixin],
    props: {
      zIndex: {
        type: [String, Number],
        default: undefined,
      },
      position: {
        type: Object,
        default: null,
      },
    },
    data: () => ({
      currentHue: 0,
    }),
    computed: {
      hex() {
        return this.colors.hex;
      },
      styles() {
        const styles = { 'z-index': this.zIndex };
        if (this.position) {
          styles.top = `${this.position.top}px`;
          styles.left = `${this.position.left}px`;
          styles.width = `${this.position.width}px`;
        }
        return styles;
      },
    },
    methods: {
      isWhite(data) {
        return data.source === 'hsl' && data.s === 0 && data.l > 0.5;
      },
      isBlack(data) {
        return data.source === 'hsl' && data.s === 0 && data.l < 0.5;
      },
      childChange(data) {
        if (data.source && ['hsl', 'hsv', 'hsla', 'hsva'].includes(data.source))
          this.currentHue = data.h || this.colors.hsl.h || 0;

        if (this.isWhite(data)) data = { h: data.h, s: 0.1, l: 0.9, source: 'hsl', a: 1 };

        if (this.isBlack(data)) data = { h: data.h, s: 0.1, l: 0.1, source: 'hsl', a: 1 };

        const hex =
          data.length === 3
            ? tinycolor(data).toString('hex3').replace('#', '').toUpperCase()
            : tinycolor(data).toHexString().toUpperCase();
        const rgba = tinycolor(data).toRgbString();

        this.$emit('change', hex);
        this.colorChange(data.source ? rgba : hex);
      },

      inputChange(data) {
        if (!data) {
          return;
        }
        if (data.hex && this.isValidHex(data.hex)) {
          this.childChange(data.hex);
        } else if (data.r || data.g || data.b || data.a) {
          const rgba = {
            r: data.r || this.colors.rgba.r,
            g: data.g || this.colors.rgba.g,
            b: data.b || this.colors.rgba.b,
            a: data.a || this.colors.rgba.a,
            source: 'rgba',
          };
          this.childChange(rgba);
        }
      },
    },
  };
</script>

<style lang="sass">
  .simple-color-picker
    position: absolute
    top: 0
    left: 0
    width: 100%
</style>
